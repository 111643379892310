<template>
  <div>
    <div>
      <div class="pt-5 pb-0">
        <h2 class="text-center text-primary font-size-20 font-weight-bold position-relative main-header mb-5">الفعاليات</h2>
        <b-container>
          <b-row>
            <b-col md="6" lg="3" class="mb-5" v-for="(i, key) in allEvents" :key="key">
              <event-card :item="i" @click.native="$router.push({name: 'eventsDetails', params: {eventsId: i.id}})"/>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import eventCard from '@/components/eventCard'
import homeServices from '@/modules/primaryPages/home/services/home'
export default {
  name: 'events',
  components: {
    eventCard
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      allEvents: []
      // events: [
      //   {
      //     title: 'الحفل الختامي لمهرجان الملك عبدالعزيز',
      //     text: 'رعى خادم الحرمين الشريفين، الملك سلمان بن عبدالعزيز آل سعود، بالصياهد الجنوبية للدهناء، اليوم، الحفل الختامي لمهرجان الملك عبد العزيز للإبل في نسخته الرابعة.',
      //     image: 'https://saudisalary.com/wp-content/uploads/2021/12/%D9%85%D9%87%D8%B1%D8%AC%D8%A7%D9%86-%D8%A7%D9%84%D9%85%D9%84%D9%83-%D8%B9%D8%A8%D8%AF%D8%A7%D9%84%D8%B9%D8%B2%D9%8A%D8%B2-%D9%84%D9%84%D8%A5%D8%A8%D9%84-%D8%A7%D9%84%D8%AA%D8%B9%D8%A7%D9%85%D9%84-%D8%A8%D8%AD%D8%B2%D9%85-%D9%85%D8%B9-%D8%A7%D9%84%D8%B9%D8%A8%D8%AB-%D9%88%D8%AA%D8%B7%D8%A8%D9%8A%D9%82-%D8%A7%D9%84%D8%A3%D9%86%D8%B8%D9%85%D8%A9.jpg'
      //   },
      //   {
      //     title: 'الحفل الختامي لمهرجان الملك عبدالعزيز',
      //     text: 'رعى خادم الحرمين الشريفين، الملك سلمان بن عبدالعزيز آل سعود، بالصياهد الجنوبية للدهناء، اليوم، الحفل الختامي لمهرجان الملك عبد العزيز للإبل في نسخته الرابعة الرابعة الرابعة الرابعة الرابعة.',
      //     image: 'https://saudisalary.com/wp-content/uploads/2021/12/%D9%85%D9%87%D8%B1%D8%AC%D8%A7%D9%86-%D8%A7%D9%84%D9%85%D9%84%D9%83-%D8%B9%D8%A8%D8%AF%D8%A7%D9%84%D8%B9%D8%B2%D9%8A%D8%B2-%D9%84%D9%84%D8%A5%D8%A8%D9%84-%D8%A7%D9%84%D8%AA%D8%B9%D8%A7%D9%85%D9%84-%D8%A8%D8%AD%D8%B2%D9%85-%D9%85%D8%B9-%D8%A7%D9%84%D8%B9%D8%A8%D8%AB-%D9%88%D8%AA%D8%B7%D8%A8%D9%8A%D9%82-%D8%A7%D9%84%D8%A3%D9%86%D8%B8%D9%85%D8%A9.jpg'
      //   },
      //   {
      //     title: 'الحفل الختامي لمهرجان الملك عبدالعزيز',
      //     text: 'رعى خادم الحرمين الشريفين، الملك سلمان بن عبدالعزيز آل سعود، بالصياهد الجنوبية للدهناء، اليوم، الحفل الختامي لمهرجان الملك عبد العزيز للإبل في نسخته الرابعة.',
      //     image: 'https://saudisalary.com/wp-content/uploads/2021/12/%D9%85%D9%87%D8%B1%D8%AC%D8%A7%D9%86-%D8%A7%D9%84%D9%85%D9%84%D9%83-%D8%B9%D8%A8%D8%AF%D8%A7%D9%84%D8%B9%D8%B2%D9%8A%D8%B2-%D9%84%D9%84%D8%A5%D8%A8%D9%84-%D8%A7%D9%84%D8%AA%D8%B9%D8%A7%D9%85%D9%84-%D8%A8%D8%AD%D8%B2%D9%85-%D9%85%D8%B9-%D8%A7%D9%84%D8%B9%D8%A8%D8%AB-%D9%88%D8%AA%D8%B7%D8%A8%D9%8A%D9%82-%D8%A7%D9%84%D8%A3%D9%86%D8%B8%D9%85%D8%A9.jpg'
      //   },
      //   {
      //     title: 'الحفل الختامي لمهرجان الملك عبدالعزيز',
      //     text: 'رعى خادم الحرمين الشريفين، الملك سلمان بن عبدالعزيز آل سعود، بالصياهد الجنوبية للدهناء، اليوم، الحفل الختامي لمهرجان الملك عبد العزيز للإبل في نسخته الرابعة.',
      //     image: 'https://saudisalary.com/wp-content/uploads/2021/12/%D9%85%D9%87%D8%B1%D8%AC%D8%A7%D9%86-%D8%A7%D9%84%D9%85%D9%84%D9%83-%D8%B9%D8%A8%D8%AF%D8%A7%D9%84%D8%B9%D8%B2%D9%8A%D8%B2-%D9%84%D9%84%D8%A5%D8%A8%D9%84-%D8%A7%D9%84%D8%AA%D8%B9%D8%A7%D9%85%D9%84-%D8%A8%D8%AD%D8%B2%D9%85-%D9%85%D8%B9-%D8%A7%D9%84%D8%B9%D8%A8%D8%AB-%D9%88%D8%AA%D8%B7%D8%A8%D9%8A%D9%82-%D8%A7%D9%84%D8%A3%D9%86%D8%B8%D9%85%D8%A9.jpg'
      //   }
      // ]
    }
  },
  methods: {
    getEvents () {
      homeServices.getAllEvents().then(res => {
        this.allEvents = res.data.data
      })
    }
  },
  created () {
    this.getEvents()
  }
}
</script>
